import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import("../views/Blog.vue"),
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../views/News.vue"),
  },
  {
    path: "/super-computer-quantum-computing",
    name: "SuperComputer",
    component: () => import("../views/pages/superComputing.vue"),
  },
  {
    path: "/drone-anti-drone",
    name: "Drone",
    component: () => import("../views/pages/drone.vue"),
  },
  {
    path: "/ai-big-data",
    name: "BigData",
    component: () => import("../views/pages/ai.vue"),
  },
  {
    path: "/cyber-securiy",
    name: "Cyber",
    component: () => import("../views/pages/cyber.vue"),
  },
  {
    path: "/innovation-centre",
    name: "InnovationCentre",
    component: () => import("../views/pages/innovation.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
