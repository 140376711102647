<template>
  <div>
    <!-- Overlay -->
    <div class="overlay" ref="overlay">
      <nav class="navbar sticky-top navbar-light bg-light">
        <div class="container-fluid px-4 px-md-4">
          <a class="navbar-brand" href="/"
            ><img src="@/assets/img/logo.png" alt="Sastra"
          /></a>
          <div class="navbar-nav">
            <a
              id="close-button"
              class="nav-link active"
              href="#"
              @click.prevent="menuDisplay(false)"
              ><span class="d-none d-md-inline-block">Close</span></a
            >
          </div>
        </div>
      </nav>
      <div class="navbar--menu-links container py-5">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <h3 class="heading heading-link">
              About Us
            </h3>
            <ul class="list-unstyled">
              <li>
                <router-link to="/about" @click.native="menuDisplay(false)"
                  >About Sastra</router-link
                >
              </li>
              <li><a href="#">Team</a></li>
              <li><a href="#">Find Opportunities</a></li>
              <li><a href="#">Contact Us</a></li>
            </ul>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <h3 class="heading heading-link">
              Resources
            </h3>
            <ul class="list-unstyled">
              <li>
                <router-link to="/blog" @click.native="menuDisplay(false)"
                  >Blog</router-link
                >
              </li>
              <!-- <li><a href="#">Guides</a></li> -->
              <li>
                <router-link to="/news" @click.native="menuDisplay(false)"
                  >News &amp; Updates</router-link
                >
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <h3 class="heading heading-link">
              Categories
            </h3>
            <ul class="list-unstyled">
              <li>
                <router-link
                  to="/super-computer-quantum-computing"
                  @click.native="menuDisplay(false)"
                  >Super Computer &amp; Quantum Computing</router-link
                >
              </li>
              <li>
                <router-link
                  to="/drone-anti-drone"
                  @click.native="menuDisplay(false)"
                  >Drone and Anti-Drone Technology</router-link
                >
              </li>
              <li>
                <router-link
                  to="/ai-big-data"
                  @click.native="menuDisplay(false)"
                  >Artifical Intelligence &amp; Big Data Analysis</router-link
                >
              </li>
              <li>
                <router-link
                  to="/cyber-securiy"
                  @click.native="menuDisplay(false)"
                  >Cyber Security - Defense &amp; Offense</router-link
                >
              </li>
              <li>
                <router-link
                  to="/innovation-centre"
                  @click.native="menuDisplay(false)"
                  >Innovation Centre for Advanced Research</router-link
                >
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <h3 class="heading heading-link">
              Forum
            </h3>
            <ul class="list-unstyled">
              <li><a href="#">Indian Navy</a></li>
              <li><a href="#">Central Armed Forces</a></li>
              <li><a href="#">Police</a></li>
            </ul>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <h3 class="heading heading-link">
              Registration
            </h3>
            <ul class="list-unstyled">
              <li><a href="#">Category 1</a></li>
              <li><a href="#">Category 2</a></li>
              <li><a href="#">Category 3</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Overlay -->
    <!-- Header -->
    <header>
      <!-- Navbar -->
      <nav class="navbar fixed-top navbar-light bg-light">
        <div class="container-fluid px-4 px-md-4">
          <a class="navbar-brand" href="/"
            ><img src="@/assets/img/logo.png" alt="Sastra"
          /></a>
          <div class="navbar-nav">
            <a
              id="menu-link"
              class="nav-link active"
              @click.prevent="menuDisplay(true)"
              href="#"
              ><span class="d-none d-md-inline-block">Menu</span></a
            >
          </div>
        </div>
      </nav>
      <!-- Navbar -->
    </header>
    <!-- Header -->
  </div>
</template>

<script>
export default {
  methods: {
    menuDisplay: function(show) {
      var overlay = this.$refs.overlay,
        body = document.body;

      if (show) {
        body.style.overflow = "hidden";
        overlay.style.width = "100%";
      } else {
        body.style.overflow = "initial";
        overlay.style.width = "0%";
      }
    },
  },
};
</script>
