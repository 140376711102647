<template>
  <div>
    <!-- Jumbotron -->
    <div class="main-container container-fluid">
      <div class="row">
        <div class="col-12 col-md-6">
          <img src="@/assets/img/background.png" class="img-fluid" alt="background" />
        </div>
        <div class="col-12 col-md-6">
          <div class="card">
            <div class="card-body">
              <h1 class="heading heading-primary">
                Making Internal Security Self-Sustainable
              </h1>
              <p class="card-text">
                An initiative that took shape from the Make In India movement.
                Not only to empower India with indigenous security related
                technology but also, make the internal security sustainable.
                <br /><br />
                The Security And Scientific Technical Research Association
                (SASTRA) aims at empowering security forces with research and
                development that gives an edge over our adversaries
              </p>
              <router-link to="/about" class="btn btn-main">Read More</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Jumbotron -->
    <!-- Content -->
    <div class="container my-5">
      <div class="row">
        <div class="col-12 my-3">
          <h1 style="font-weight: 700;">
            Superlative and cutting-edge internal security innovations
          </h1>
          <p>
            SASTRA scours the domestic talent pool for impactful technology
            aided human resource for the best future for national security.
          </p>
        </div>
        <div class="col-12 col-md-4 ">
          <div class="row">
            <router-link to="/ai-big-data">
              <div class="col-12">
                <div class="icon-wrap">
                  <img src="@/assets/img/icons/robot.png" alt="Aritificial Intelligence" />
                </div>
              </div>
              <div class="col-12 icon-text d-flex justify-content-center">
                Artifical Intelligence
              </div>
            </router-link>
          </div>
        </div>

        <div class="col-12 col-md-4 ">
          <div class="row">
            <router-link to="/ai-big-data">
              <div class="col-12">
                <div class="icon-wrap">
                  <img src="@/assets/img/icons/eye-scanner.png" alt="Big Data" />
                </div>
              </div>
              <div class="col-12 icon-text d-flex justify-content-center">
                Big Data Analysis
              </div>
            </router-link>
          </div>
        </div>

        <div class="col-12 col-md-4 ">
          <div class="row">
            <router-link to="/cyber-securiy">
              <div class="col-12">
                <div class="icon-wrap">
                  <img src="@/assets/img/icons/satellites.png" alt="Cyber Security" />
                </div>
              </div>
              <div class="col-12 icon-text d-flex justify-content-center">
                Cyber Security
              </div>
            </router-link>
          </div>
        </div>

        <div class="col-12 col-md-4 ">
          <div class="row">
            <router-link to="/drone-anti-drone">
              <div class="col-12">
                <div class="icon-wrap">
                  <img src="@/assets/img/icons/drone.png" alt="Drone" />
                </div>
              </div>
              <div class="col-12 icon-text d-flex justify-content-center">
                Drone Technology
              </div>
            </router-link>
          </div>
        </div>

        <div class="col-12 col-md-4 ">
          <div class="row">
            <router-link to="/super-computer-quantum-computing">
              <div class="col-12">
                <div class="icon-wrap">
                  <img src="@/assets/img/icons/chip.png" alt="Quantum Computing" />
                </div>
              </div>
              <div class="col-12 icon-text d-flex justify-content-center">
                Quantum Computing
              </div>
            </router-link>
          </div>
        </div>

        <div class="col-12 col-md-4 ">
          <div class="row">
            <router-link to="/super-computer-quantum-computing">
              <div class="col-12">
                <div class="icon-wrap">
                  <img src="@/assets/img/icons/brain.png" alt="Super Computer" />
                </div>
              </div>
              <div class="col-12 icon-text d-flex justify-content-center">
                Super Computer
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="container my-5">
      <div class="row">
        <div class="col-12 col-md-6">
          <img src="@/assets/img/hgv.jpg" class="img-fluid" alt="HGV" />
        </div>
        <div class="col-12 col-md-6">
          <h1 class="heading heading-primary">
            India’s first Hypersonic Glide Vehicle: HGV-202F
          </h1>
          <p class="card-text">
            Hypersonic weapons incorporate the speed of a ballistic missile with
            the maneuvering capabilities of a cruise missile. Hypersonic weapons
            refer to weapons that travel faster than Mach 5 (3,800mph) and have
            the capability to maneuver during the entire flight.
          </p>
          <a href="pages/first-hgv-blog.html" class="btn btn-main">Read More</a>
        </div>
      </div>
    </div>
    <div class="container my-5">
      <div class="row">
        <div class="col-12 col-md text-center">
          <img class="img-fluid" src="@/assets/img/vision.jpg" alt="Vision" />
          <h3 class="heading">Vision</h3>
          <ul class="list" style="text-align: start;">
            <li>
              To create multi-disciplinary communities of original thinkers,
              technologically savvy and mission-focused individuals and/ or
              entities.
            </li>
            <li>
              To incubate new ideas which strengthen multi-domain warfare
              capabilities and cross-domain deterrence.
            </li>
            <li>
              To serve as a technology accelerator for self-reliance and export.
            </li>
          </ul>
        </div>
        <div class="col-12 col-md text-center">
          <img class="img-fluid" src="@/assets/img/mission.jpg" alt="Mission" />
          <h3 class="heading">Mission</h3>
          <ul class="list" style="text-align: start;">
            <li>
              Be a forward-leaning organization geared to stimulate innovation.
            </li>
            <li>
              Foster and nurture curious minds to innovate and become
              thought-leaders.
            </li>
            <li>
              Increase private sector commercialization of innovations in the
              security sector.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Content -->
  </div>
</template>

<script>
export default {};
</script>
